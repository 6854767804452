import Experience from '../Experience.js'
import Dressingroom from './Dressingroom.js'
import Door from './Door.js'
import Shirts from './Shirts.js';
import Video from './Video.js';
import Tip from './Tip.js';
import Screens from './Screens.js';
import Particles from './Particles.js';

export default class World
{
    constructor()
    {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources

        // Wait for resources
        this.resources.on('ready', () =>
        {
            // Setup
            this.dressingroom = new Dressingroom()
            this.door = new Door()
            this.video = new Video()
            this.shirts = new Shirts()
            this.particles = new Particles()
            this.tip = new Tip()
            this.screens = new Screens()
        })
    }

    update() {
      if (this.particles) {
        this.particles.update()
      }
    }
}
