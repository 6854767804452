import * as THREE from 'three'
import Experience from '../Experience.js'
import gsap from 'gsap';
import {DecalGeometry} from 'three/addons/geometries/DecalGeometry.js';

export default class Shirts {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.video = this.experience.world.video
    this.door = this.experience.world.door
    this.dressingroom = this.experience.world.dressingroom
    this.raycaster = this.experience.raycaster
    this.resources = this.experience.resources
    this.environment = this.experience.environment

    this.animationTimeout = null;
    // this.canvasName = document.createElement('canvas');
    this.canvasName = {};
    this.allShirts = [];
    this.shirtShadows = [
      {
        id: 'shirt1',
        bottomShadow: null,
        bottomPosition: [0, 0, 2],
        wallShadow: null,
        wallPosition: [0, -2, 2],
      },
      {
        id: 'shirt2',
        bottomShadow: null,
        bottomPosition: [-2, 0, 0],
        wallShadow: null,
        wallPosition: [-3, -2, 0],
      },
      {
        id: 'shirt3',
        bottomShadow: null,
        bottomPosition: [-2, 0, 0],
        wallShadow: null,
        wallPosition: [-3, -2, 0],
      },
      {
        id: 'shirt4',
        bottomShadow: null,
        bottomPosition: [-2, 0, 0],
        wallShadow: null,
        wallPosition: [-3, -2, 0],
      },
      {
        id: 'shirt5',
        bottomShadow: null,
        bottomPosition: [0, 0, -2],
        wallShadow: null,
        wallPosition: [0, -2, -2],
      },
      {
        id: 'shirt6',
        bottomShadow: null,
        bottomPosition: [0, 0, -2],
        wallShadow: null,
        wallPosition: [0, -2, -2],
      },
    ];
    this.scene.scene = this.scene.children.filter(obj => obj.name === 'Scene')[0];
    this.customizableShirt = this.scene.scene.children.filter(obj => obj.name === 'shirt3')[0];

    this.setCustomShirtCanvas();
    this.setShadow()

    this.shirtShadows.forEach((item) => {
      this.allShirts.push(this.scene.scene.children.filter(obj => obj.name === item.id)[0]);
    });

    this.setVideos();
  }

  setShadow() {
    this.shirtShadows.forEach((item) => {
      const currentShirt = this.scene.scene.children.filter(obj => obj.name === item.id)[0];

      // Set the bottom shadow for each shirt
      item.bottomShadowGeometry = new THREE.PlaneGeometry(3, 3)
      item.bottomShadowMaterial = new THREE.MeshBasicMaterial({
        color: 0x000000,
        transparent: true,
        alphaMap: this.resources.items.simpleShadow
      })
      item.bottomShadowMesh = new THREE.Mesh(item.bottomShadowGeometry, item.bottomShadowMaterial)
      item.bottomShadowMesh.material.opacity = 0.2
      item.bottomShadowMesh.rotation.x = -Math.PI * 0.5
      item.bottomShadowMesh.position.set(
        currentShirt.position.x + item.bottomPosition[0],
        0.5,
        currentShirt.position.z + item.bottomPosition[2]
      )

      // Set the wall shadow for each shirt
      item.wallShadowGeometry = new THREE.PlaneGeometry(4, 6)
      item.wallShadowMaterial = new THREE.MeshBasicMaterial({
        color: 0x000000,
        transparent: true,
        side: THREE.DoubleSide,
        alphaMap: this.resources.items.simpleShadow,
        opacity: 0.4
      })
      item.wallShadowMesh = new THREE.Mesh(item.wallShadowGeometry, item.wallShadowMaterial)
      item.wallShadowMesh.rotation.y = currentShirt.rotation.y
      item.wallShadowMesh.position.set(
        currentShirt.position.x + item.wallPosition[0],
        currentShirt.position.y + item.wallPosition[1],
        currentShirt.position.z + item.wallPosition[2]
      )

      // Add the shadows to the scene
      this.scene.add(item.bottomShadowMesh, item.wallShadowMesh)
    })
  }

  clickOnShirt() {
    const element = this.raycaster.raycaster.currentHover;
    let activeShirt;

    if (!element) {
      return;
    }

    if (this.shirtShadows.filter(obj => obj.id === element.object.name).length) {
      activeShirt = element.object // If click on shirt
    } else if (element.object.parent && this.shirtShadows.filter(obj => obj.id === element.object.parent.name).length) {
      activeShirt = element.object.parent // If click on shirtnumber
    } else if (element.object.name === 'customShirtNameAndNumber') {
      activeShirt = this.customizableShirt // If click on custom shirtnumber
    }

    if (activeShirt) {
      this.animateShirts(activeShirt)
    } else {
      this.animateShirts()
    }

  }

  animateShirts(shirt) {
    let shirtToCenter = null;
    let shirtToOriginal = null;

    if (!this.door.hallwayAnimationComplete) {
      return;
    }

    if (shirt) {
      if (this.allShirts.find(obj => obj.selectedShirt === true)) {
        shirtToOriginal = this.allShirts.find(obj => obj.selectedShirt === true);
      }

      this.door.stopAudio()
      shirt.selectedShirt = !shirt.selectedShirt;

      if (shirt.selectedShirt) {
        shirt.originalPosition = shirt.position.clone();
        shirt.originalRotation = shirt.rotation.clone();
        shirtToCenter = shirt;
      } else {
        shirtToOriginal = shirt;
      }

      this.allShirts.forEach((item) => {
        if (item !== shirt) {
          item.selectedShirt = false;

          if (item.selectedShirt) {
            shirtToOriginal = item;
          }
        }
      });
    } else {
      shirtToOriginal = this.allShirts.find(obj => obj.selectedShirt === true);
    }

    if (shirtToOriginal) {
      this.animateShirtToOriginalPosition(shirtToOriginal)
      this.video.stop()

      // Change environment to normal
      clearTimeout(this.animationTimeout)
      this.dressingroom.setModel('textureLight')
      this.environment.mainRoomLight.color = new THREE.Color(0x111111);
      this.environment.mainRoomLight.intensity = 750
      this.environment.spotLight.visible = false;
      this.environment.destroySpotLightCone()
      this.experience.world.particles.destroyParticles()
    }

    if (shirtToCenter) {
      this.animateShirtToCenter(shirtToCenter);
      this.video.play(shirtToCenter.name)

      // Change environment to dark
      this.animationTimeout = setTimeout(() => {
        this.dressingroom.setModel('textureDark')
        this.environment.mainRoomLight.color = new THREE.Color(0x040617);
        this.environment.mainRoomLight.intensity = 8
        this.environment.spotLight.visible = true;
        this.environment.setSpotLightCone()
        this.experience.world.particles.setParticles()
      }, 1500)
    }
  }

  setVideos() {
    this.allShirts.forEach((item) => {
      // if (item.name === 'shirt3') {
      //   return;
      // }

      const videoElement = document.createElement('video');
      videoElement.setAttribute('id', item.name)
      // videoElement.setAttribute('muted', '');
      videoElement.setAttribute('playsinline', '');

      const source = document.createElement('source');
      source.setAttribute('src', `videos/${item.name}.mp4?v=6`);
      source.setAttribute('type', 'video/mp4');

      videoElement.appendChild(source);
      document.body.appendChild(videoElement);
      videoElement.load();
    });
  }

  drawCanvas() {
    const urlParams = new URLSearchParams(window.location.search);
    const shirtName = urlParams.get('name') ? urlParams.get('name') : 'OYM';
    const shirtNumber = urlParams.get('number') ? urlParams.get('number') : '1';

    this.canvasName = document.createElement('canvas');
    this.canvasNameCtx = this.canvasName.getContext('2d');
    this.canvasName.width = 512;
    this.canvasName.height = 1024;
    this.canvasNameCtx.fillStyle = 'rgba(255, 0, 0, 0)';
    this.canvasNameCtx.fillRect(0, 0, this.canvasName.width, this.canvasName.height);
    this.canvasNameCtx.fillStyle = 'rgba(0, 0, 0, 1)';
    this.canvasNameCtx.textAlign = 'center';
    this.canvasNameCtx.font = '100px Nike';
    this.canvasNameCtx.fillText(shirtName.toUpperCase(), 256, 200);
    this.canvasNameCtx.font = '450px Nike';
    this.canvasNameCtx.fillText(shirtNumber, 256, 700);
  }

  setCustomShirtCanvas() {
    const waitForFontCheck = () => {
      return new Promise(resolve => {
        function checkFont() {
          if (document.fonts.check('1em Nike')) {
            resolve();
          } else {
            setTimeout(checkFont, 100);
          }
        }

        checkFont();
      });
    }

    waitForFontCheck().then(() => {
      this.drawCanvas();
      this.setDecal();
    });
  }

  setDecal() {
    this.decalOffsetX = 0.4
    this.decalOffsetY = 0.6
    this.decalTexture = new THREE.Texture(this.canvasName);
    this.decalTexture.needsUpdate = true;

    // this.decalGeometry = new DecalGeometry(
    //   this.customizableShirt,
    //   new THREE.Vector3(0, -0.6, 0.3), // position
    //   new THREE.Vector3(0, 0, 0),      // orientation
    //   new THREE.Vector3(2.2, 4, 0.5)   // size
    // );

    this.decalGeometry = new THREE.PlaneGeometry(2.2, 4);

    this.decalMaterial = new THREE.MeshBasicMaterial({
      map: this.decalTexture,
      depthWrite: false,
      polygonOffset: true,
      polygonOffsetUnits: -10,
      polygonOffsetFactor: -10,
      transparent: 1
    });
    this.decalMaterial.needsUpdate = true;

    this.decalMesh = new THREE.Mesh(this.decalGeometry, this.decalMaterial);
    this.decalMesh.name = 'customShirtNameAndNumber';
    this.decalMesh.position.set(this.customizableShirt.position.x + this.decalOffsetX, this.customizableShirt.position.y - this.decalOffsetY, this.customizableShirt.position.z)
    this.decalMesh.rotation.set(this.customizableShirt.rotation.x, this.customizableShirt.rotation.y, this.customizableShirt.rotation.z)
    this.decalMesh.scale.set(this.customizableShirt.scale.x, this.customizableShirt.scale.y, this.customizableShirt.scale.z)

    this.scene.add(this.decalMesh);
  }

  animateShirtToCenter(shirt) {
    // Shirt
    gsap.to(shirt.position, {
      duration: 1,
      delay: 0,
      x: -13,
      y: 6.5,
      z: 0,
    })

    gsap.to(shirt.rotation, {
      duration: 1,
      delay: 0,
      y: (Math.PI * 2) + (Math.PI / 2),
    })

    // Shadow
    const item = this.shirtShadows.filter(obj => obj.id === shirt.name)[0];
    gsap.to(item.bottomShadowMesh.position, {
      duration: 1,
      delay: 0,
      x: -13,
      z: 0,
    })

    gsap.to(item.wallShadowMesh.material, {
      duration: 0.4,
      delay: 0,
      opacity: 0
    })

    if (shirt.name !== 'shirt3') {
      return;
    }

    // Decal
    gsap.to(this.decalMesh.position, {
      duration: 1,
      delay: 0,
      x: -13 + this.decalOffsetX,
      y: 6.5 - this.decalOffsetY,
      z: 0,
    })

    gsap.to(this.decalMesh.rotation, {
      duration: 1,
      delay: 0,
      y: (Math.PI * 2) + (Math.PI / 2),
    })
  }

  animateShirtToOriginalPosition(shirt) {
    // Shirt
    gsap.to(shirt.position, {
      duration: 1,
      delay: 0,
      x: shirt.originalPosition.x,
      y: shirt.originalPosition.y,
      z: shirt.originalPosition.z,
    })

    gsap.to(shirt.rotation, {
      duration: 1,
      delay: 0,
      y: shirt.originalRotation.y,
    })

    // Shadow
    const item = this.shirtShadows.filter(obj => obj.id === shirt.name)[0];
    gsap.to(item.bottomShadowMesh.position, {
      duration: 1,
      delay: 0,
      x: shirt.originalPosition.x + item.bottomPosition[0],
      z: shirt.originalPosition.z + item.bottomPosition[2],
    })

    gsap.to(item.wallShadowMesh.material, {
      duration: 0.4,
      delay: 0.6,
      opacity: 0.3
    })

    if (shirt.name !== 'shirt3') {
      return;
    }

    // Decal
    gsap.to(this.decalMesh.position, {
      duration: 1,
      delay: 0,
      x: shirt.originalPosition.x + this.decalOffsetX,
      y: shirt.originalPosition.y - this.decalOffsetY,
      z: shirt.originalPosition.z,
    })

    gsap.to(this.decalMesh.rotation, {
      duration: 1,
      delay: 0,
      y: shirt.originalRotation.y,
    })
  }
}
