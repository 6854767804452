import Experience from './Experience.js'
import gsap from 'gsap';
import {CAMERA_POSITION_END_Y} from './config.js';

export default class Controls
{
  constructor()
  {
    this.experience = new Experience()
    this.sizes = this.experience.sizes
    this.scene = this.experience.scene
    this.raycaster = this.experience.raycaster
    this.camera = this.experience.camera
    this.resources = this.experience.resources

    this.startAngle = null;

    // if (window.location.hash === '#debug') {
    //   // Enable free controls
    //   this.freeControls = false
    //   document.querySelector('.switch-controls').addEventListener('click', () => {
    //     this.freeControls = true;
    //     this.camera.controls.enabled = true;
    //     this.camera.controls.update();
    //   })
    // } else {
    //   document.querySelector('.permission').style.display = 'none';
    // }
  }

  async requestPermisison() {
    return new Promise((resolve) => {
      if (typeof( DeviceMotionEvent ) === "undefined" || typeof DeviceOrientationEvent.requestPermission !== 'function') {
        window.addEventListener('deviceorientation', (e) => {
          this.animateCamera(this.cameraPositionForMotion(e));
        });

        resolve(true)
        return;
      }

      DeviceOrientationEvent.requestPermission().then(r => {
        if (r === 'granted') {
          window.addEventListener('deviceorientation', (e) => {
            this.animateCamera(this.cameraPositionForMotion(e));
          });

          resolve(true)
        } else if (r === 'denied') {
          resolve(false)
        }
      })
    });
  }

  /**
   * Return the position of the camera for mouse interactions
   * @returns {{y: number, z: number}}
   */
  cameraPositionForMouse() {
    return {
      y: CAMERA_POSITION_END_Y + (this.raycaster.cursor.y / -60),
      z: this.raycaster.cursor.x / (15 * (this.sizes.width / this.sizes.height))
    };
  }

  /**
   * Return the position of the camera for motion interactions
   * @param e
   * @returns {{y: number, z: number}}
   */
  cameraPositionForMotion(e) {
    const rotation = this.getEulerAngles(this.getRotationMatrix(e.alpha, e.beta, e.gamma));
    let updown = rotation[1].toFixed(2)
    let leftright = (rotation[2] + 90).toFixed(2)

    if (!this.startAngle) {
      this.startAngle = parseInt(updown);
    }

    if (updown > this.startAngle + 20) {
      updown = this.startAngle + 20;
    } else if (updown < this.startAngle + -20) {
      updown = this.startAngle + -20
    }

    if (leftright < -40 || leftright > 180 ) {
      leftright = -40;
    } else if (leftright > 40) {
      leftright = 40
    }

    return {
      y: CAMERA_POSITION_END_Y + ((updown - this.startAngle) / -600),
      z: leftright / -(400 * (this.sizes.width / this.sizes.height))
    };
  }

  /**
   * Animate the camera to position
   * @param position
   */
  animateCamera(position) {
    gsap.to(this.camera.instance.position, {
      duration: 1,
      delay: 0,
      y: position.y,
      z: position.z
    })
  }

  /**
   * Calculate the rotation matrix
   * @param alpha
   * @param beta
   * @param gamma
   * @returns {number[]}
   */
  getRotationMatrix( alpha, beta, gamma ) {
    const degtorad = Math.PI / 180; // Degree-to-Radian conversion
    const cX = Math.cos( beta  * degtorad );
    const cY = Math.cos( gamma * degtorad );
    const cZ = Math.cos( alpha * degtorad );
    const sX = Math.sin( beta  * degtorad );
    const sY = Math.sin( gamma * degtorad );
    const sZ = Math.sin( alpha * degtorad );

    const m11 = cZ * cY - sZ * sX * sY;
    const m12 = - cX * sZ;
    const m13 = cY * sZ * sX + cZ * sY;

    const m21 = cY * sZ + cZ * sX * sY;
    const m22 = cZ * cX;
    const m23 = sZ * sY - cZ * cY * sX;

    const m31 = - cX * sY;
    const m32 = sX;
    const m33 = cX * cY;

    return [
      m13, m11, m12,
      m23, m21, m22,
      m33, m31, m32
    ];
  };

  /**
   * calculate the euler angles
   * @param matrix
   * @returns {number[]}
   */
  getEulerAngles( matrix ) {
    const radtodeg = 180 / Math.PI; // Radian-to-Degree conversion
    const sy = Math.sqrt(matrix[0] * matrix[0] +  matrix[3] * matrix[3] );
    const singular = sy < 1e-6; // If
    let x;
    let y;
    let z;

    if (!singular) {
      x = Math.atan2(matrix[7] , matrix[8]);
      y = Math.atan2(-matrix[6], sy);
      z = Math.atan2(matrix[3], matrix[0]);
    } else {
      x = Math.atan2(-matrix[5], matrix[4]);
      y = Math.atan2(-matrix[6], sy);
      z = 0;
    }

    return [radtodeg * x, radtodeg * y, radtodeg * z];
  }
}
