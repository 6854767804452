import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { FontLoader } from 'three/addons/loaders/FontLoader.js';
import EventEmitter from './EventEmitter.js'
import {gsap} from 'gsap';
import Experience from '../Experience.js';
import Loader from '../World/Loader.js';

export default class Resources extends EventEmitter
{
    constructor(sources)
    {
        super()

        this.experience = new Experience()
        this.loader = new Loader()
        this.controls = this.experience.controls
        this.sources = sources
        this.mobileAndTabletCheck = this.experience.mobileAndTabletCheck

        this.items = {}
        this.toLoad = this.sources.length
        this.loaded = 0
        this.loadingComplete = false;

        this.setLoadingManager()
        this.setLoaders()
        this.startLoading()
    }

    setLoadingManager()
    {
      this.loadingBarElement = document.querySelector('.loading__bar');
      this.loadingButtonElement = document.querySelector('.loading__button');
      this.loadingScreenElement = document.querySelector('.loading__screen');

      this.loadingManager = new THREE.LoadingManager(
        // Loaded
        () => {
          window.setTimeout(() => {
            this.loadingScreenElement.classList.add('visible');
            this.loadingBarElement.classList.add('ended');
            this.loadingBarElement.style.transform = '';

            this.loadingButtonElement.addEventListener('click', async () => {
              // request permission for mobile controls
              const result = await this.controls.requestPermisison();

              if (result) {
                // remove loading screen
                gsap.to(this.loader.overlayMaterial.uniforms.uAlpha, {
                  duration: 3,
                  delay: 0.2,
                  value: 0
                })
                this.loadingScreenElement.classList.remove('visible');

                // Loading complete
                setTimeout(() => {
                  document.documentElement.classList.add('init');

                  this.loadingComplete = true;
                })

                if (this.mobileAndTabletCheck.check()) {
                  document.documentElement.classList.add('show-tip');

                  setTimeout(() => {
                    document.documentElement.classList.remove('show-tip');
                  }, 4000)
                }
              } else {
                alert("Op deze pagina maken we gebruik van sensoren in uw telefoon, en u hebt ons geen toegang tot de sensoren verleend. Als u uw keuze wilt wijzigen, sluit dan deze pagina en heropen deze. Klik op 'Sta toe' wanneer het verzoek verschijnt.");
              }
            })
          }, 600);
        },
        // Progress
        (itemUrl, itemsLoaded, itemsTotal) => {
          const progressRatio = itemsLoaded / itemsTotal;
          this.loadingBarElement.style.transform = `scaleX(${progressRatio})`;
        }
      );
    }

    setPermission(accepted) {
      console.log(accepted);
      this.permission = accepted;
    }

    setLoaders()
    {
        this.loaders = {}
        this.loaders.gltfLoader = new GLTFLoader(this.loadingManager)
        this.loaders.textureLoader = new THREE.TextureLoader(this.loadingManager)
        this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader(this.loadingManager)
        this.loaders.fontLoader = new FontLoader(this.loadingManager);
        this.loaders.audioLoader = new THREE.AudioLoader(this.loadingManager);
    }

    startLoading()
    {
      // Load each source
        for(const source of this.sources)
        {
            if(source.type === 'font') {
              this.loaders.fontLoader.load(
                source.path,
                (file) =>
                {
                  this.sourceLoaded(source, file)
                },
              )
            }
            else if(source.type === 'audio') {
              this.loaders.audioLoader.load(
                source.path,
                (file) =>
                {
                  this.sourceLoaded(source, file)
                },
              )
            }
            else if(source.type === 'gltfModel')
            {
                this.loaders.gltfLoader.load(
                    source.path,
                    (file) =>
                    {
                        this.sourceLoaded(source, file)
                    }
                )
            }
            else if(source.type === 'texture')
            {
                this.loaders.textureLoader.load(
                    source.path,
                    (file) =>
                    {
                        this.sourceLoaded(source, file)
                    }
                )
            }
            else if(source.type === 'cubeTexture')
            {
                this.loaders.cubeTextureLoader.load(
                    source.path,
                    (file) =>
                    {
                        this.sourceLoaded(source, file)
                    }
                )
            }
        }
    }

    sourceLoaded(source, file)
    {
        this.items[source.name] = file

        this.loaded++

        if(this.loaded === this.toLoad)
        {
            this.trigger('ready')
        }
    }
}
