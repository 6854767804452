import * as THREE from 'three'
import Experience from './Experience.js'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import {
  CAMERA_FOV,
  CAMERA_POSITION_START_X,
  CAMERA_POSITION_START_Y,
  CAMERA_POSITION_START_Z
} from './config.js';

export default class Camera
{
    constructor()
    {
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas

        this.setInstance()
        this.setControls()
    }

    setInstance()
    {
        this.instance = new THREE.PerspectiveCamera(CAMERA_FOV, this.sizes.width / this.sizes.height, 0.01, 120)
        this.instance.position.set(CAMERA_POSITION_START_X, CAMERA_POSITION_START_Y, CAMERA_POSITION_START_Z)
        this.scene.add(this.instance)
    }

    setControls()
    {
        this.controls = new OrbitControls(this.instance, this.canvas)
        this.controls.enableDamping = true
        this.controls.target = new THREE.Vector3(CAMERA_POSITION_START_X - 0.3, CAMERA_POSITION_START_Y, CAMERA_POSITION_START_Z);
        this.controls.enabled = false;
        this.controls.update();
    }

    resize()
    {
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }

    update()
    {
        this.controls.update()
    }
}
