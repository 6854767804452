import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Dressingroom {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.model = this.resources.items.dressingroomModel.scene
    this.bakeElements = [
      {
        textureLight: 'baked1-light',
        textureDark: 'baked1-dark',
        elements: ['door', 'door_handle', 'door_sign', 'door_edges', 'front_top', 'front_wall', 'rail', 'street'],
        texture: null,
        material: null,
      },
      {
        textureLight: 'baked2-light',
        textureDark: 'baked2-dark',
        elements: ['hallway', 'hallway_floor', 'frame_right_1', 'frame_right_2', 'frame_right_3', 'frame_right_4', 'frame_left_1', 'frame_left_2', 'frame_left_3', 'frame_left_4'],
        texture: null,
        material: null,
      },
      {
        textureLight: 'baked3-light',
        textureDark: 'baked3-dark',
        elements: ['floor', 'shower', 'tiles', 'tiles_pink', 'video_wall', 'walls_roof'],
        texture: null,
        material: null,
      },
      {
        textureLight: 'baked4-light',
        textureDark: 'baked4-dark',
        elements: ['bench_left_board', 'bench_left_hooks', 'bench_left_seat', 'bench_new_left_frame', 'bench_middle_board', 'bench_middle_hooks', 'bench_middle_seat', 'bench_new_middle_frame', 'bench_right_board', 'bench_right_hooks', 'bench_right_seat', 'bench_new_right_frame'],
        texture: null,
        material: null,
      },
      {
        textureLight: 'baked5-light',
        textureDark: 'baked5-dark',
        elements: ['football_1_G', 'football_1_G_1', 'football_2_G','football_2_G_1','football_3_G','football_3_G_1', 'bidon_1', 'bidon_2', 'bidon_3', 'bidon_4', 'bidon_5', 'bidon_6', 'bidon_7', 'towel_1', 'towel_2', 'bag', 'shoes'],
        texture: null,
        material: null,
      }
    ]

    this.setModel('textureLight')
  }

  setModel(texture) {
    for (const bakeElementsKey in this.bakeElements) {
      this.bakeElements[bakeElementsKey].texture = this.resources.items[this.bakeElements[bakeElementsKey][texture]]
      this.bakeElements[bakeElementsKey].texture.flipY = false
      this.bakeElements[bakeElementsKey].texture.colorSpace = THREE.SRGBColorSpace
      this.bakeElements[bakeElementsKey].material = new THREE.MeshBasicMaterial({map: this.bakeElements[bakeElementsKey].texture})
    }

    this.model.traverse((child) => {
      for (const bakeElementsKey in this.bakeElements) {
        if (this.bakeElements[bakeElementsKey].elements.includes(child.name)) {
          child.material = this.bakeElements[bakeElementsKey].material;
        }
      }
    })

    this.scene.add(this.model)
  }
}
