export const CAMERA_FOV = 65;
export const CAMERA_HALLWAY_ANIMATION_DURATION = 18;

export const CAMERA_POSITION_START_X = 60;
export const CAMERA_POSITION_START_Y = 8;
export const CAMERA_POSITION_START_Z = 0;

export const CAMERA_POSITION_END_X = -6;
export const CAMERA_POSITION_END_Y = 8;
export const CAMERA_POSITION_END_Z = 0;
