import * as THREE from 'three'
import Experience from '../Experience.js'
import Time from '../Utils/Time.js';

export default class Particles {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.time = new Time()
  }

  setParticles() {
    this.particlesCount = 100
    const positions = new Float32Array(this.particlesCount * 3)

    for (let i = 0; i < this.particlesCount * 3; i++) {
      if (i % 3 === 0) {
        positions[i] = (Math.random() - 0.5) * 2 // depth
      } else if (i % 3 === 1) {
        positions[i] = (Math.random() - 0.5) * 8 // height
      } else {
        positions[i] = (Math.random() - 0.5) * 4 // width
      }
    }

    this.particlesGeometry = new THREE.BufferGeometry()
    this.particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
    this.particlesMaterial = new THREE.PointsMaterial({
      size: 0.05,
      sizeAttenuation: true,
      color: "#ffe9ef",
      transparent: true,
      alphaMap: this.resources.items.particle,
      depthWrite: false,
      opacity: 0.5
    })

    this.particles = new THREE.Points(this.particlesGeometry, this.particlesMaterial)
    this.particles.name = 'sparks'
    this.particles.originalPosition = [...this.particlesGeometry.attributes.position.array];
    this.particles.position.set(-11, 8, 0);

    this.scene.add(this.particles)
  }

  destroyParticles() {
    this.particlesGeometry.dispose();
    this.particlesMaterial.dispose();
    this.scene.remove(this.particles);
  }

  update() {
    if (!this.particlesGeometry) {
      return
    }

    for (let i = 0; i < this.particlesCount; i++) {
      const i3 = i * 3
      const x = this.particlesGeometry.attributes.position.array[i3]

      if (i3 % 4 === 0) {
        this.particlesGeometry.attributes.position.array[i3 + 1] = this.particles.originalPosition[i3 + 1] + (Math.sin(this.time.elapsed / 20000 + x)) * -1
      } else if (i3 % 4 === 1) {
        this.particlesGeometry.attributes.position.array[i3 + 2] = this.particles.originalPosition[i3 + 2] + (Math.sin(this.time.elapsed / 20000 + x)) * -1
      } else if (i3 % 4 === 2) {
        this.particlesGeometry.attributes.position.array[i3 + 1] = this.particles.originalPosition[i3 + 1] + (Math.sin(this.time.elapsed / 20000 + x))
      } else if (i3 % 4 === 3) {
        this.particlesGeometry.attributes.position.array[i3 + 2] = this.particles.originalPosition[i3 + 2] + (Math.sin(this.time.elapsed / 20000 + x))
      }
    }

    this.particlesGeometry.attributes.position.needsUpdate = true
  }
}
