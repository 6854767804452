import * as THREE from 'three'
import Experience from '../Experience.js'
import gsap from 'gsap';

export default class Video
{
  constructor()
  {
    this.experience = new Experience()
    this.scene = this.experience.scene

    this.scene.scene = this.scene.children.filter(obj => obj.name === 'Scene')[0];
    this.wall = this.scene.scene.children.filter(obj => obj.name === 'video_wall')[0];
    this.wall.originalMaterial = this.wall.material.clone();

    this.videoElement = null;
    this.videoShowTimeout = null
    this.videoPlayTimeout = null
  }

  setVideoScreen(id) {
    this.videoElement = document.getElementById( id );
    this.videoTexture = new THREE.VideoTexture( this.videoElement );
    this.videoTexture.colorSpace = THREE.SRGBColorSpace;
    this.videoTexture.wrapS = THREE.RepeatWrapping;
    this.videoTexture.wrapT = THREE.RepeatWrapping;
    this.videoTexture.magFilter = THREE.NearestFilter;
    this.videoTexture.minFilter = THREE.NearestFilter;

    this.videoScreenBoundingBox = new THREE.Box3().setFromObject(this.wall);
    this.videoScreenGeometry = new THREE.PlaneGeometry(
      this.videoScreenBoundingBox.max.z - this.videoScreenBoundingBox.min.z + 0.2,
      this.videoScreenBoundingBox.max.y - this.videoScreenBoundingBox.min.y + 0.2
    );
    this.videoScreenMaterial = new THREE.MeshBasicMaterial({
      map: this.videoTexture,
      transparent: true,
      opacity: 0
    });

    this.videoScreenMesh = new THREE.Mesh( this.videoScreenGeometry, this.videoScreenMaterial );
    this.videoScreenMesh.rotation.y = Math.PI * 0.5;
    this.videoScreenMesh.position.set(this.wall.position.x + 0.05, this.wall.position.y, this.wall.position.z)

    this.scene.add( this.videoScreenMesh );
  }

  stopVideoScreen() {
    this.videoScreenMesh.geometry.dispose();
    this.videoScreenMesh.material.dispose();
    this.scene.remove( this.videoScreenMesh );
  }

  play(id) {
    this.setVideoScreen(id)

    // Show in video
    this.videoShowTimeout = setTimeout(() => {
      this.videoScreenMesh.material.opacity = 1
    }, 1500)

    // Play video
    this.videoPlayTimeout = setTimeout(() => {
      this.videoElement.play()
    }, 2000);
  }

  stop() {
    this.stopVideoScreen();

    // Fade out video
    gsap.to(this.videoScreenMesh.material, {
      duration: 1,
      delay: 0,
      opacity: 0
    })

    clearTimeout(this.videoShowTimeout);
    clearTimeout(this.videoPlayTimeout);

    // Stop video
    this.videoElement.pause()
    this.videoElement.currentTime = 0
  }
}
