import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Screens
{
  constructor()
  {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources

    this.scene.scene = this.scene.children.filter(obj => obj.name === 'Scene')[0];
    this.screens = [
      {
        id: 'screen_left_1',
        screen: {},
        frame: {},
        rotation: Math.PI
      },
      {
        id: 'screen_left_2',
        screen: {},
        frame: {},
        rotation: Math.PI
      },
      {
        id: 'screen_left_3',
        screen: {},
        frame: {},
        rotation: Math.PI
      },
      {
        id: 'screen_left_4',
        screen: {},
        frame: {},
        rotation: Math.PI
      },
      {
        id: 'screen_right_1',
        screen: {},
        frame: {},
        rotation: 0
      },
      {
        id: 'screen_right_2',
        screen: {},
        frame: {},
        rotation: 0
      },
      {
        id: 'screen_right_3',
        screen: {},
        frame: {},
        rotation: 0
      },
      {
        id: 'screen_right_4',
        screen: {},
        frame: {},
        rotation: 0
      }
    ]

    // Traverse the whole scene
    this.scene.traverse((child) =>
    {
      this.screens.filter(item => {
        if (item.id === child.name) {
          item.screen = child
          item.frame = child.parent

          this.setFrameImage(item);
        }
      })
    })
  }

  setFrameImage(item) {
    this.resources.items[item.id].generateMipmaps = false
    this.screenBoundingbox = new THREE.Box3().setFromObject(item.screen);
    this.frameImageGeometry = new THREE.PlaneGeometry( this.screenBoundingbox.max.x - this.screenBoundingbox.min.x, this.screenBoundingbox.max.y - this.screenBoundingbox.min.y );
    this.frameImageMaterial = new THREE.MeshBasicMaterial({
      side: THREE.DoubleSide,
      map: this.resources.items[item.id]
    })
    this.frameImageMesh = new THREE.Mesh( this.frameImageGeometry, this.frameImageMaterial );
    this.frameImageMesh.rotation.y = item.rotation;
    this.frameImageMesh.position.set(item.frame.position.x, item.frame.position.y, item.frame.position.z)
    this.scene.add( this.frameImageMesh );
  }
}
