
import Experience from './Experience/Experience.js'

const experience = new Experience(document.querySelector('canvas.webgl'))


//translations
if (window.navigator.language === 'nl' || window.navigator.language === 'nl-NL') {
  document.querySelector('.loading__button').innerHTML = 'Stap binnen in onze kleedkamer'
  document.querySelector('.tip__text').innerHTML = 'Beweeg je apparaat om rond te kijken'
}
