import * as THREE from 'three'
import Experience from './Experience.js'

export default class Raycaster {
  constructor() {
    this.experience = new Experience()
    this.camera = this.experience.camera
    this.scene = this.experience.scene
    this.renderer = this.experience.renderer
    this.sizes = this.experience.sizes

    this.setRaycaster()
  }

  setRaycaster() {
    this.raycaster = new THREE.Raycaster();
    this.cursor = new THREE.Vector2();
  }

  setPointerMoveListener(eventX, eventY) {
    this.cursor.x = ( eventX / this.sizes.width ) * 2 - 1;
    this.cursor.y = - ( eventY / this.sizes.height ) * 2 + 1;

    this.update();
  }

  update() {
    this.raycaster.setFromCamera(this.cursor, this.camera.instance);
    this.raycaster.intersects = this.raycaster.intersectObjects(this.scene.children);

    if (this.raycaster.intersects.length > 0) {
      this.raycaster.intersects.every((item, index) => {
        if (item.object.name === 'sparks' || item.object.name === 'lightcone') {
          return true;
        }

        this.raycaster.currentHover = item
        return false;
      })
    } else {
      this.raycaster.currentHover = null;
    }

    this.renderer.instance.render(this.scene, this.camera.instance);
  }
}
