import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Environment {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.debug = this.experience.debug
    this.resources = this.experience.resources

    // Debug
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder('environment')
    }

    // Init lights
    this.setMainRoomLight()
    this.setSpotLight()
  }

  setMainRoomLight() {
    this.mainRoomLight = new THREE.PointLight(0x111111, 750, 50, 1);
    this.mainRoomLight.position.set(-8, 15, 0);
    this.mainRoomLight.decay = 0;
    // this.mainRoomLightHelper = new THREE.PointLightHelper(this.mainRoomLight, 1);

    this.scene.add(this.mainRoomLight);
    // this.scene.add(this.mainRoomLightHelper);

    // Debug
    if (this.debug.active) {
      this.debugFolder.add(this.mainRoomLight, 'intensity').name('MainRoomLightIntensity').min(0).max(10000).step(0.001)
      this.debugFolder.add(this.mainRoomLight.position, 'x').name('MainRoomLightX').min(-30).max(90).step(0.001)
      this.debugFolder.add(this.mainRoomLight.position, 'y').name('MainRoomLightY').min(-30).max(30).step(0.001)
      this.debugFolder.add(this.mainRoomLight.position, 'z').name('MainRoomLightZ').min(-30).max(30).step(0.001)
    }
  }

  setSpotLight() {
    this.spotLight = new THREE.SpotLight('#fff', 50, 15, 0.4, 1, 0)
    this.spotLight.position.set(-8, 15, 0);
    this.spotLight.target.position.set(-12, 0, 0)
    this.spotLight.target.updateMatrixWorld();
    this.spotLight.visible = false;

    this.scene.add(this.spotLight);
  }

  setSpotLightCone() {
    this.lightconeGeometry = new THREE.PlaneGeometry(5, 16);
    this.lightconeMaterial = new THREE.MeshBasicMaterial({
      transparent: true,
      map: this.resources.items.spotlight
    })
    this.lightconeMesh = new THREE.Mesh(this.lightconeGeometry, this.lightconeMaterial)
    this.lightconeMesh.name = 'lightcone';
    this.lightconeMesh.rotation.y = Math.PI * 0.5
    this.lightconeMesh.position.set(-12.5, 8, 0)

    this.scene.add(this.lightconeMesh)
  }

  destroySpotLightCone() {
    this.lightconeGeometry.dispose();
    this.lightconeMaterial.dispose();
    this.scene.remove(this.lightconeMesh);
  }
}
