import Experience from './Experience.js'

export default class Shirts
{
  constructor()
  {
    this.experience = new Experience()
    this.raycaster = this.experience.raycaster
    this.interactableElements = [
      'clickBlock',
      'shirt1',
      'shirt-gangmakers',
      'shirt2',
      'shirt-vernieuwers',
      // 'shirt3',
      // 'customShirtNameAndNumber',
      'shirt4',
      'shirt-winnaars',
      'shirt5',
      'shirt-liefhebbers',
      'shirt6',
      'shirt-teamgenoten'
    ]
  }

  cursorStyling(event) {
    const element = this.raycaster.raycaster.currentHover;
    const cursorElement = document.querySelector('.cursor');

    cursorElement.style.transform = `translate3d(${event.clientX}px, ${event.clientY}px, 0) translate(-50%, -50%)`;
    cursorElement.style.opacity = 1;

    if (element && this.interactableElements.includes(element.object.name)) {
      cursorElement.classList.add('cursor--hover')
    } else {
      cursorElement.classList.remove('cursor--hover')
    }
  }
}
