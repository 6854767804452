export default [
  // Font
  // {
  //   name: 'Nike Football',
  //   type: 'font',
  //   path: '/fonts/nike-football.json'
  // },

  // Audio
  // {
  //   name: 'hallwayAudio',
  //   type: 'audio',
  //   path: '/audio/hallway.mp3'
  // },

  // Misc
  {
    name: 'simpleShadow',
    type: 'texture',
    path: 'textures/misc/simpleShadow.jpg'
  },
  {
    name: 'spotlight',
    type: 'texture',
    path: 'textures/misc/spotlight-new.png'
  },
  {
    name: 'particle',
    type: 'texture',
    path: 'textures/misc/circle_05.png'
  },

  // Screens
  {
    name: 'screen_left_1',
    type: 'texture',
    path: 'textures/screens/trilogie.jpg'
  },
  {
    name: 'screen_left_2',
    type: 'texture',
    path: 'textures/screens/vdhoogenband.jpg'
  },
  {
    name: 'screen_left_3',
    type: 'texture',
    path: 'textures/screens/koeman.jpg'
  },
  {
    name: 'screen_left_4',
    type: 'texture',
    path: 'textures/screens/cruijff.jpg'
  },
  {
    name: 'screen_right_1',
    type: 'texture',
    path: 'textures/screens/verstappen.jpg'
  },
  {
    name: 'screen_right_2',
    type: 'texture',
    path: 'textures/screens/fosbury.jpg'
  },
  {
    name: 'screen_right_3',
    type: 'texture',
    path: 'textures/screens/kipchoge.jpg'
  },
  {
    name: 'screen_right_4',
    type: 'texture',
    path: 'textures/screens/jordan.jpg'
  },

  // Baked
  {
    name: 'baked1-light',
    type: 'texture',
    path: 'textures/baked/baked_2-4_1-light.jpg'
  },
  {
    name: 'baked1-dark',
    type: 'texture',
    path: 'textures/baked/baked_2-4_1-dark.jpg'
  },
  {
    name: 'baked2-light',
    type: 'texture',
    path: 'textures/baked/baked_2-4_2-light.jpg'
  },
  {
    name: 'baked2-dark',
    type: 'texture',
    path: 'textures/baked/baked_2-4_2-dark.jpg'
  },
  {
    name: 'baked3-light',
    type: 'texture',
    path: 'textures/baked/baked_2-4_3-light.jpg'
  },
  {
    name: 'baked3-dark',
    type: 'texture',
    path: 'textures/baked/baked_2-4_3-dark.jpg'
  },
  {
    name: 'baked4-light',
    type: 'texture',
    path: 'textures/baked/baked_2-4_4-light.jpg'
  },
  {
    name: 'baked4-dark',
    type: 'texture',
    path: 'textures/baked/baked_2-4_4-dark.jpg'
  },
  {
    name: 'baked5-light',
    type: 'texture',
    path: 'textures/baked/baked_2-4_5-light.jpg'
  },
  {
    name: 'baked5-dark',
    type: 'texture',
    path: 'textures/baked/baked_2-4_5-dark.jpg'
  },

  // Model
  {
    name: 'dressingroomModel',
    type: 'gltfModel',
    path: 'models/dressingroom/dressingroom57.glb'
  }
]
