import * as THREE from 'three'
import Experience from '../Experience.js'
import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase';
import {CAMERA_HALLWAY_ANIMATION_DURATION, CAMERA_POSITION_END_X, CAMERA_POSITION_END_Y, CAMERA_POSITION_END_Z} from '../config.js';

export default class Dressingroom {
  constructor() {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.resources = this.experience.resources
    this.raycaster = this.experience.raycaster
    this.camera = this.experience.camera
    this.controls = this.experience.controls
    this.mobileAndTabletCheck = this.experience.mobileAndTabletCheck

    this.sceneElement = this.scene.children.filter(obj => obj.name === 'Scene')[0];
    this.doorElement = this.sceneElement.children.filter(obj => obj.name === 'door')[0];
    this.doorHandleElement = this.doorElement.children.filter(obj => obj.name === 'door_handle')[0];

    this.hallwayAnimationComplete = false;
    this.setClickBlock()

    this.audio = new Audio('audio/hallway-new.mp3');
  }

  // setDoorHandleShadow() {
  //   // Set the shadow for the door handle
  //   this.doorHandleGeometry = new THREE.PlaneGeometry(1, 0.3)
  //   this.doorHandleMaterial = new THREE.MeshBasicMaterial({
  //     color: 0x000000,
  //     transparent: true,
  //     alphaMap: this.resources.items.simpleShadow,
  //     depthWrite: false,
  //     side: THREE.DoubleSide
  //   })
  //   this.doorHandleShadow = new THREE.Mesh(this.doorHandleGeometry, this.doorHandleMaterial)
  //   this.doorHandleShadow.rotation.y = Math.PI * 0.5
  //   this.doorHandleShadow.position.set(this.doorElement.position.x + 0.3, this.doorElement.position.y, this.doorElement.position.z - 4.6)
  //
  //   this.scene.add(this.doorHandleShadow)
  // }

  animateOpenDoor() {
    gsap.to(this.doorHandleElement.rotation, {
      duration: 0.5,
      delay: 0,
      z: Math.PI / -8
    })
    // gsap.to(this.doorHandleShadow.rotation, {
    //   duration: 0.5,
    //   delay: 0,
    //   x: Math.PI * 0.15
    // })

    setTimeout(() => {
      // this.scene.remove(this.doorHandleShadow);

      gsap.to(this.doorElement.rotation, {
        duration: 1,
        delay: 0,
        y: Math.PI / 1.8
      })
    }, 750)
  }

  playAudio() {
    // this.audioListener = new THREE.AudioListener();
    // this.camera.instance.add( this.audioListener );
    // this.hallwaySound = new THREE.Audio( this.audioListener );
    // this.scene.add( this.hallwaySound );
    // this.hallwaySound.setBuffer( this.resources.items.hallwayAudio );
    // this.hallwaySound.play();

    // trick mobile safari into playing this.hallwaySound

    this.audio.play();
  }

  stopAudio() {
    // gsap.to(this.hallwaySound.gain.gain, {
    //   duration: 1,
    //   value: 0,
    //   onComplete: () => {
    //     this.hallwaySound.stop();
    //   },
    // });

    this.audio.pause();
  }

  enterDressingRoom() {
    const element = this.raycaster.raycaster.currentHover;

    if (!element || element.object.name !== 'clickBlock') {
      return;
    }

    this.animateOpenDoor();
    this.playAudio();

    // Set custom ease
    gsap.registerPlugin(CustomEase)
    const customEasingFunction = CustomEase.create('custom', 'M0,0 C0.042,0 0.097,0 0.496,0.496 0.907,1.008 0.949,1 1,1 ');

    setTimeout(() => {
      gsap.to(this.camera.instance.position, {
        duration: CAMERA_HALLWAY_ANIMATION_DURATION,
        ease: customEasingFunction,
        delay: 0,
        x: CAMERA_POSITION_END_X,
        y: CAMERA_POSITION_END_Y,
        z: CAMERA_POSITION_END_Z,
        onUpdate: () => {
          if (!this.mobileAndTabletCheck.check()) {
            this.controls.animateCamera(this.experience.controls.cameraPositionForMouse());
          }
        }
      })
      gsap.to(this.camera.controls.target, {
        duration: CAMERA_HALLWAY_ANIMATION_DURATION,
        ease: customEasingFunction,
        delay: 0,
        x: CAMERA_POSITION_END_X - 0.1,
        y: CAMERA_POSITION_END_Y,
        z: CAMERA_POSITION_END_Z,
        onComplete: () => {
          this.hallwayAnimationComplete = true;
        }
      })
    }, 1000)

    this.destroyClickBlock();
  }

  setClickBlock() {
    this.clickBlockGeometry = new THREE.BoxGeometry(1, 13, 6);
    this.clickBlockMaterial = new THREE.MeshBasicMaterial({
      color: 0xff1152,
      transparent: true,
      opacity: 0.01
    });
    this.clickBlockMesh = new THREE.Mesh(this.clickBlockGeometry, this.clickBlockMaterial);
    this.clickBlockMesh.name = 'clickBlock';
    this.clickBlockMesh.position.set(47.5, 7.1, -0.8)
    this.scene.add(this.clickBlockMesh);
  }

  destroyClickBlock() {
    this.clickBlockGeometry.dispose();
    this.clickBlockMaterial.dispose();
    this.scene.remove(this.clickBlockMesh);
  }
}
