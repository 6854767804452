import * as THREE from 'three'
import Experience from '../Experience.js'

export default class Tip
{
  constructor()
  {
    this.experience = new Experience();
    this.scene = this.experience.scene;

    setTimeout(() => {
      document.querySelector('.js-tip').classList.add('hidden');
    }, 3000)
  }
}
