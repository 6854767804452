import * as THREE from 'three'
import Experience from './Experience.js'
// import Dressingroom from 'World/Dressingroom.js'

export default class Shirts
{
  constructor()
  {
    this.experience = new Experience()
    this.scene = this.experience.scene
    this.raycaster = this.experience.raycaster
    this.controls = this.experience.controls
    this.world = this.experience.world
    this.cursor = this.experience.cursor
    this.mobileAndTabletCheck = this.experience.mobileAndTabletCheck
    this.resources = this.experience.resources

    if (this.mobileAndTabletCheck.check()) {
      window.addEventListener('touchstart', (event) => {
        if(!this.resources.loadingComplete) {
          return;
        }

        this.raycaster.setPointerMoveListener(event.touches[0].clientX, event.touches[0].clientY)
        this.world.door.enterDressingRoom();
        this.world.shirts.clickOnShirt();
      });
    } else {
      window.addEventListener('pointermove', (event) => {
        if(!this.resources.loadingComplete) {
          return;
        }

        this.cursor.cursorStyling(event);
        this.controls.animateCamera(this.experience.controls.cameraPositionForMouse());
        this.raycaster.setPointerMoveListener(event.clientX, event.clientY)
      });

      window.addEventListener('click', () => {
        if(!this.resources.loadingComplete) {
          return;
        }

        // console.log(this.raycaster.raycaster.currentHover);

        this.world.door.enterDressingRoom();
        this.world.shirts.clickOnShirt();
      });
    }
  }
}
